<template>
	<h3 class="text-center mt-4 c-fff">{{store.state.lang.vcPoolText}} - {{store.state.lang.assetsList}}</h3>
	<div class="text-center c-fff">{{store.state.lang.assetsListInfo}}</div>
	<div class="row mt-4">
		<div class="col-md-4 col-sm-12 col-12 mb-3">
			<img class="w-100" :src="require('../../../assets/img/icon/assets_1.png')" alt="">
		</div>
		<div class="col-md-4 col-sm-12 col-12 mb-3">
			<img class="w-100" :src="require('../../../assets/img/icon/assets_2.png')" alt="">
		</div>
		<div class="col-md-4 col-sm-12 col-12 mb-3">
			<img class="w-100" :src="require('../../../assets/img/icon/assets_3.png')" alt="">
		</div>
	</div>
	<div style="padding: 0 15px;box-sizing: border-box;">
		<div class="row mt-4 paper">
			<h4 class="col-sm-12 mb-4">{{store.state.lang.Networks}}</h4>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_BSC.png')" alt="">
				BSC
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_Ethereum.png')" alt="">
				Ethereum
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_Solana.png')" alt="">
				Solana
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_Tron.png')" alt="">
				Tron
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_Polygon.png')" alt="">
				Polygon
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_Arbitrum.png')" alt="">
				Arbitrum
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_Avalanche.png')" alt="">
				Avalanche
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_Fantom.png')" alt="">
				Fantom
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_OKC.png')" alt="">
				OKC
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_FIL.png')" alt="">
				Filcoin
			</div>
		</div>
	</div>
	<div class="asset-bar ls-flex-ajc mt-4">
		<div class="ls-flex-ajc asset-bar-item ls-flex-1" :class="{'active':assetIndex==0}" @click="changeAssetIndex(0)">{{store.state.lang.AvailableAssets}}</div>
		<div class="ls-flex-ajc asset-bar-item ls-flex-1 ml-4" :class="{'active':assetIndex==1}" @click="changeAssetIndex(1)">{{store.state.lang.ObservingAssets}}</div>
	</div>
	
	<div class="row asset-table-h mt-4" v-if="!assetIndex">
		<div class="col-sm-5 col-4">{{store.state.lang.Name}}</div>
		<div class="col-sm-4 col-4">{{store.state.lang.Balance}}</div>
		<div class="col-sm-3 col-4">{{store.state.lang.ProposalDAO}}</div>
	</div>
	<el-divider v-if="!assetIndex" />
	
	<div style="padding: 0 15px;box-sizing: border-box;" v-if="!assetIndex">
		<div class="row paper available">
			<div class="col-sm-5 col-5 ls-flex-ac">
				#1
				<img class="bt-icon" :src="require('../../../assets/img/icon_BTC.png')" alt="">
				BTC
			</div>
			<div class="col-sm-4 col-4 ls-flex-ac">$1.254863</div>
			<div class="col-sm-3 col-3 ls-flex-ac">
				<img class="bt-icon" :src="require('../../../assets/img/icon/icon_logo.png')" alt="">
			</div>
		</div>
		<div class="row paper available mt-3">
			<div class="col-sm-5 col-5 ls-flex-ac">
				#2
				<img class="bt-icon" :src="require('../../../assets/img/icon_ETH.png')" alt="">
				ETH
			</div>
			<div class="col-sm-4 col-4 ls-flex-ac">$1.254863</div>
			<div class="col-sm-3 col-3 ls-flex-ac">
				<img class="bt-icon" :src="require('../../../assets/img/icon/icon_logo.png')" alt="">
			</div>
		</div>
		<div class="row paper available mt-3">
			<div class="col-sm-5 col-5 ls-flex-ac">
				#3
				<img class="bt-icon" :src="require('../../../assets/img/icon_BNB.png')" alt="">
				BNB
			</div>
			<div class="col-sm-4 col-4 ls-flex-ac">$1.254863</div>
			<div class="col-sm-3 col-3 ls-flex-ac">
				<img class="bt-icon" :src="require('../../../assets/img/icon/icon_logo.png')" alt="">
			</div>
		</div>
		<div class="row paper available mt-3">
			<div class="col-sm-5 col-5 ls-flex-ac">
				#4
				<img class="bt-icon" :src="require('../../../assets/img/icon_FIL.png')" alt="">
				FIL
			</div>
			<div class="col-sm-4 col-4 ls-flex-ac">$1.254863</div>
			<div class="col-sm-3 col-3 ls-flex-ac">
				<img class="bt-icon" :src="require('../../../assets/img/icon/icon_logo.png')" alt="">
			</div>
		</div>
	</div>
	
	<div style="padding: 0 15px;box-sizing: border-box;" v-if="assetIndex">
		<div class="row mt-4 paper">
			<h4 class="col-sm-12 mb-4">{{store.state.lang.ObservingAssets}}</h4>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_Solana.png')" alt="">
				SOL
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_ADA.png')" alt="">
				ADA
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_DOT.png')" alt="">
				DOT
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_AVAX.svg')" alt="">
				AVAX
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_UNI.png')" alt="">
				UNI
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_LTC.png')" alt="">
				LTC
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_FTT.png')" alt="">
				FTT
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_LINK.png')" alt="">
				LINK
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_GALA.png')" alt="">
				GALA
			</div>
			<div class="col-md-3 col-6 col-lg-2 networks-item">
				<img :src="require('../../../assets/img/icon_FTM.png')" alt="">
				FTM
			</div>
		</div>
	</div>
</template>

<script setup>
	import {ref} from 'vue';
	import {useStore} from 'vuex';
	
	const store = useStore();
	
	const assetIndex = ref(0);
	
	const changeAssetIndex = (index)=>{
		assetIndex.value = index;
	}
</script>

<style scoped>
	.networks-item{
		font-weight: 500;
		color: #fff;
		line-height: 42px;
	}
	.networks-item img{
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}
	.asset-table-h div{
		padding: 0 30px;
		box-sizing: border-box;
		font-weight: 500;
		color: #fff;
	}
	.available{
		height: 85px;
	}
	.available div{
		font-weight: 500;
	}
	.available div img{
		width: 32px;
		height: 32px;
		margin: 0 10px;
	}
	
	@media (max-width:768px) {
		
	}
</style>